import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { E } from "../src/ProgramScheduler/ProgramSchedulerController.web";
let config = require("../../../framework/src/config");
import { CometChat } from "@cometchat-pro/chat"
import moment from "moment";
const appSetting = new CometChat.AppSettingsBuilder()
.subscribePresenceForAllUsers()
.setRegion(config.region)
.autoEstablishSocketConnection(true)
.build();
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children?: any;
  hideSidebar?: boolean;
  courseTitle?: any;
  isQuestionView?: boolean;
  isListView?: boolean;
  questionNumber?: number;
  totalQuestions?: number;
  youMatterSectionId?: any;
  youMatterTitle?: string;
  handleQuestionChange?: (page: number) => void;
  handleViewChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // Customizable Area End
}
// Customizable Area Start
interface User {
  birthday: string;
  email: string;
  first_name: string;
  gender: string;
  id: number;
  image_url: string;
  last_name: string;
  parents_email: string;
  role: "Student" | "Trainer" | "Parent" | "School";
  school_class: string;
  school_name: string;
  status: string;
  bio: string;
  aadhar_card_file_name: string;
  aadhar_card_number: string;
  aadhar_photo_url: string;
  address_line1: string;
  address_line2: string;
  state: string;
  city: string;
  pincode: string;
}

interface Progress {
  mpower: string;
}

interface MindMasteryList {
  id: number;
  title: string;
  description: string;
  thumbnail: string;
}

export interface MindMasteryProps {
  id: string;
  type: "mind_mastery_course";
  attributes: MindMasteryList;
}

interface SuperpowerList {
  title: string,
  name: string,
  count: number,
}

export interface SuperpowerProps {
  id: string | number;
  type: string;
  attributes: SuperpowerList;
}

interface NotesList {
  title: string;
  description: string;
}

export interface NotesProps {
  id: string;
  type: "notes";
  attributes: NotesList;
}

interface ReportDetails {
  id: number;
  name: string;
}

interface ReportList {
  id: string;
  status: string;
  pdf_url?: string;
  mind_mastery_course: ReportDetails;
  student: ReportDetails;
}

export interface ReportProps {
  id: string;
  type: "student_report";
  attributes: ReportList;
}

interface MpowerList {
  id: string;
  course_name?: string;
  end_date?: string;
  grade: string;
  school: string;
  date: string;
  status: string;
  title: string;
  name: string;
  section: string;
}

export interface MpowerProps {
  id: string;
  type: "mpower_check";
  attributes: MpowerList;
}

interface YouPowerList {
  course_name?: string;
  due_date: string;
  grade: string;
  school: string;
  name: string;
  status: string;
  start_date: string;
  section: string;
}

interface ErrorStatus {
  address_line1: boolean;
  state: boolean;
  city: boolean;
  pincode: boolean;
}

export interface YouMaterProps {
  id: string;
  type: "trainer";
  attributes: YouPowerList;
}

interface OnGoingProgramList {
  course_name?: string;
  date: string;
  grade: string;
  school_name: string;
  duration: string;
  status: string;
  city: string;
  notes: string;
  program_progress: string;
  document_url: string;
  description: string;
  thumbnail_url: string;

}

export interface OnGoingProgramProps {
  id: string;
  type: "ongoing_program";
  attributes: OnGoingProgramList;
}

interface Rules {
  upper: boolean;
  lower: boolean;
  specialChar: boolean;
  number: boolean;
  length: boolean;
  notEqual: string;
}

export interface EventData {
  id: string;
  type: string;
  course_id?: number;
  attributes: {
    name: string;
    time: string;
    date: string;
    end_date?: string;
    submission_status?: string;
    organizer: {
      name: string;
      profile_photo_url: string | null;
    }
  }
}

export interface CourseStatus {
  id: string;
  type: string;
  attributes: {
    id: number,
    student_id: number,
    mind_mastery_course_id: number,
    status: string;
  }
}

// Customizable Area End
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  showProfileModal?: boolean;
  userDetails: User;
  progress: Progress;
  mindMastery: Array<MindMasteryProps>;
  superPowers: Array<SuperpowerProps>;
  notes: Array<NotesProps>;
  reports: Array<ReportProps>;
  showPopup: boolean;
  selectedEvent: E;
  events: any;
  upcomingEvents: any;
  confirmPwd: any;
  tempPwd: any;
  profileIcon: any;
  avatarKey: any;
  showPwd: boolean;
  showConfPwd: boolean;
  rules: Rules;
  mPower: any;
  youMatter: any;
  userDetail: any;
  onGoingProgram: any;
  ongoingProgramIndex: number;
  schoolDetails: any;
  onGoingPrograms: any;
  onGoingProgramsList: any;
  parentDetails: any;
  parentsChildrenOnPrograms: any;
  page: number;
  bio: string;
  role: any;
  ParentGuideData: any;
  openSnackBar:boolean;
  snackBarMessage:string;
  snackBarType:"success" | "error" | "warning" | "info";
  openCancel: boolean;
  currentDate: Date;
  passwordError: boolean;
  successModal: boolean;
  validationErrors: {
    address_line1: boolean;
    state: boolean,
    city: boolean,
    pincode: boolean,
    bios: boolean
  };
  validationErrorsForSchool: {
    address_line1: boolean;
    state: boolean,
    city: boolean,
    pincode: boolean,
  };
  pincodeError: boolean;
  cityError: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  userDetailsApiCallId: string = "";
  progressDetailApiCallId: string = "";
  mindMasteryApiCallId: string = "";
  superPowerApiCallId: string = "";
  reportApiCallId: string = "";
  notesApiCallId: string = "";
  eventsDataApiCallId: string = "";
  apiMPowerItemCallId: string = "";
  apiYouMatterItemCallId: string = "";
  apiOngoingProgramItemCallId: string = "";
  apiUserDetailItemCallId: string = "";
  programDetailsApiCallId: string = "";

  getSchoolDetailsApiCallId: string = "";
  getSchoolOnGoingProgramsApiCallId: string = "";
  getSchoolOnGoingProgramsListApiCallId: string = "";
  getParentDetailsApiCallId: string = "";
  getParentsChildrenProgramsApiCallId: string = "";
  ParentGuideApi: string = "";
  upcomingEventsApiCallId: string = "";
  updateProfileApiCallId: string = "";
  coursesStatusApiCallId: string = "";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      ParentGuideData: {},
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      showProfileModal: false,
      showPopup: false,
      userDetails: {} as User,
      progress: {} as Progress,
      selectedEvent: {} as E,
      mindMastery: [],
      superPowers: [],
      notes: [],
      reports: [],
      events: [],
      upcomingEvents: [],
      confirmPwd: "",
      tempPwd: "",
      profileIcon: "",
      avatarKey: "",
      showPwd: false,
      showConfPwd: false,
      rules: {
        upper: false,
        lower: false,
        specialChar: false,
        number: false,
        length: false,
        notEqual: ""
      },
      mPower: [],
      youMatter: [],
      userDetail: [],
      onGoingProgram: { programs: [] },
      ongoingProgramIndex: 0,
      schoolDetails: {},
      onGoingPrograms: [],
      onGoingProgramsList: [],
      parentDetails: {},
      parentsChildrenOnPrograms: [],
      page: 1,
      bio: "",
      role: "",
      openSnackBar:false,
      snackBarMessage:"",
      snackBarType: "success",
      openCancel: false,
      currentDate: new Date(),
      passwordError: false,
      successModal: false,
      validationErrors: {
        address_line1: false,
        state: false,
        city: false,
        pincode: false,
        bios: false
        },
      validationErrorsForSchool: {
        address_line1: false,
        state: false,
        city: false,
        pincode: false,
      },
      pincodeError: false,
      cityError: false
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.fetchCourseStatus = this.fetchCourseStatus.bind(this);
    this.handleCourseStatus = this.handleCourseStatus.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(responseJson.error){
        this.setState({openSnackBar:true, snackBarType:"error" ,snackBarMessage:responseJson.error})
        return;
      }
      if (apiRequestCallId === this.userDetailsApiCallId) {
        const responseUserDetails = responseJson.user;
        if (responseUserDetails.data) {
          localStorage.setItem(
            "userDetails",
            JSON.stringify(responseUserDetails.data)
          );
          let data = responseUserDetails.data.attributes;
          data.bio = responseUserDetails.data.attributes.bio || "";

          this.setState({
            userDetails: responseUserDetails.data.attributes,
            bio: responseUserDetails.data.attributes.bio || ""
          });
          this.loginCometChatUser(responseUserDetails.data);
        }
      }
      this.getData(responseJson, apiRequestCallId);
      const responseData = responseJson;
      this.switchCases(responseData, apiRequestCallId);
      this.handleSchoolDashboardResponse(apiRequestCallId, responseJson);
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleAssemblrSuccessResponse(responseJson);
      } else {
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.handleAssemblrErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  switchCases = (responseData: any, apiRequestCallId: any) => {
    switch (apiRequestCallId) {
      case this.mindMasteryApiCallId:
        this.setState({ mindMastery: responseData.data });
        break;
      case this.notesApiCallId:
        this.setState({ notes: responseData.data });
        break;
      case this.superPowerApiCallId:
        this.setState({ superPowers: responseData.data });
        break;
      case this.reportApiCallId:
        this.setState({ reports: responseData.reports });
        break;
      case this.eventsDataApiCallId:
        if(Array.isArray(responseData.data)) {
          this.setState({ events: responseData.data });
        }
        break;
      case this.upcomingEventsApiCallId:
        this.setState({ upcomingEvents: responseData.data });
        break;
      case this.programDetailsApiCallId:
        let _eventsData: any= this.state.events;
        const responseJson = responseData.data;
        _eventsData = _eventsData.map((item : any)=> {
          if (item.id === responseJson.id) {
            return {
              ...item,
              ...responseJson.attributes
            };
          } else {
            return { ...item };
          }
        });
        this.setState({ events: _eventsData });
        break;
      case this.updateProfileApiCallId:
        localStorage.removeItem(configJSON.Dashboard.UserDetails);
        this.getUserDetails();
        this.updateGroupProfile(responseData.data.attributes.image_url);
        break;
      case this.apiUserDetailItemCallId:
        this.setState({ userDetail: responseData });
        break;
      case this.apiOngoingProgramItemCallId:
        this.setState({ onGoingProgram: responseData });
        break;
      case this.apiMPowerItemCallId:
        this.setState({ mPower: responseData });
        break;
      case this.apiYouMatterItemCallId:
        this.setState({ youMatter: responseData });
        break;
      case this.coursesStatusApiCallId:
        this.handleCourseStatus(responseData.data);
        break;
    }
  };

  handleAssemblrSuccessResponse = (responseJson: any) => {
    if (responseJson.data.length === 0) {
      this.setState({
        errorMsg: configJSON.Dashboard.DataNotFound,
        loading: false
      });
    } else {
      this.setState({
        dashboardData: responseJson.data,
        errorMsg: "",
        loading: false
      });
    }
  };

  handleAssemblrErrorResponse = (errorReponse: any) => {
    if (errorReponse === undefined) {
      this.setState({
        errorMsg: configJSON.Dashboard.SomethingWrong,
        loading: false
      });
    } else {
      this.setState({
        errorMsg: errorReponse,
        loading: false
      });
    }
  };

  handleSchoolDashboardResponse = (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    switch (apiRequestCallId) {
      case this.getSchoolDetailsApiCallId:
        this.setState({ schoolDetails: responseJson });
        break;
      case this.getSchoolOnGoingProgramsApiCallId:
        this.setState({ onGoingPrograms: responseJson.programs });
        break;
      case this.getSchoolOnGoingProgramsListApiCallId:
        this.setState({ onGoingProgramsList: responseJson.programs_list });
        break;
      case this.getParentDetailsApiCallId:
        this.setState({ parentDetails: responseJson.data });
        break;
      case this.getParentsChildrenProgramsApiCallId:
        this.setState({ parentsChildrenOnPrograms: responseJson.data });
        break;
    }
  };

  goTo = (module: string, params: Object = {}) => {
    this.props.navigation.navigate(module, { ...params });
  };

  getAuthToken = () => {
    return localStorage.getItem("user_token");
  };

  getUserRole = () => {
    const result = localStorage.getItem("role")
    this.setState({ role: result });
    return result
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  handleShowPwd = (name: string) => {
    if (name === configJSON.Dashboard.PWD) {
      this.setState({ showPwd: !this.state.showPwd });
    } else {
      this.setState({ showConfPwd: !this.state.showConfPwd });
    }
  };

  handleSetProfileState = (profileIcon: any) => {
    this.setState({ profileIcon: profileIcon, avatarKey: "" });
  };

  handleCancel = () => {
    this.handleModalOpen();
  };

  handlePwdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let updatedRules = this.state.rules;
    if (value.length > 0) {
      updatedRules = {
        ...updatedRules,
        ...this.validateCharacter(/[A-Z]/, value, configJSON.Dashboard.Upper)
      };
      updatedRules = {
        ...updatedRules,
        ...this.validateCharacter(/[a-z]/, value, configJSON.Dashboard.Lower)
      };
      updatedRules = {
        ...updatedRules,
        ...this.validateCharacter(/\d/, value, configJSON.Dashboard.Number)
      };
      updatedRules = {
        ...updatedRules,
        ...this.validateCharacter(/\W/, value, configJSON.Dashboard.SpecialChar)
      };
      updatedRules = {
        ...updatedRules,
        ...this.validateCharacter(/^.{8,}$/, value, configJSON.Dashboard.Length)
      };
      updatedRules = { ...updatedRules, ...this.validateNotEqual(name, value) };
    } else {
      updatedRules = {
        upper: false,
        lower: false,
        specialChar: false,
        number: false,
        length: false,
        notEqual: ""
      };
    }
    const updatedError = updatedRules.upper && updatedRules.lower && updatedRules.specialChar && updatedRules.number && updatedRules.length
    if (name === "confirmPwd") {
      const notEqualRule = this.validateNotEqual(name, value);
      this.setState((prevState) => ({
        ...prevState,[name]: value, rules: {...prevState.rules,notEqual:notEqualRule?.notEqual}
      }));
    }else{
      this.setState({ ...this.state, [name]: value, rules: updatedRules ,passwordError: !updatedError});

    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if(name === "bio"){
      this.setState({ ...this.state, [name]: value, userDetails: {...this.state.userDetails, [name]: value } },()=> this.setState({validationErrors: {...this.state.validationErrors, bios: false}}));
    }
    else if (name === "pincode") {
      const pincodeRegex = /^\d+$/;
      if (pincodeRegex.test(value) || value === "") {
        this.setState((prevState) => ({ ...prevState, userDetails: { ...prevState.userDetails, [name]: value }, validationErrors: { ...prevState.validationErrors, pincode: false }, pincodeError: false }));
      }
      else {
        this.setState((prevState) => ({ ...prevState, validationErrors: { ...prevState.validationErrors, pincode: false }, pincodeError: true, validationErrorsForSchool: { ...prevState.validationErrorsForSchool, pincode: false } }));
      }
    }
    else if(name === "city") {
      this.setState((prevState) => ({ ...prevState, userDetails: { ...prevState.userDetails, [name]: value }}),() => {this.setState({cityError: false})});
    }
    else {
      this.setState({ ...this.state, [name]: value, userDetails: { ...this.state.userDetails, [name]: value }, validationErrors: { ...this.state.validationErrors, [name]: false }, validationErrorsForSchool: { ...this.state.validationErrorsForSchool, [name]: false } });
    }
  };

  validateCharacter = (regex: RegExp, value: any, rule: any) => {
    if (regex.test(value)) {
      return { [rule]: true };
    } else {
      return { [rule]: false };
    }
  };

  validateNotEqual = (name: any, value: any) => {
    if (name === configJSON.Dashboard.ConfirmPwd && this.state.tempPwd !== value) {
      return { notEqual: configJSON.Dashboard.PasswordsMustBeSame };
    } else if (this.state.tempPwd === value) {
      return { notEqual: "" };
    }
  };

  handleLeftRightNavigation = (type: string) => {
    if (type === "inc") {
      this.setState({
        ongoingProgramIndex: this.state.ongoingProgramIndex + 1
      });
    } else {
      this.setState({
        ongoingProgramIndex: this.state.ongoingProgramIndex - 1
      });
    }
  };

  async togglePopup(eventData: EventData) {
    const { id, type, course_id, attributes } = eventData;
    const time = attributes.time.replace("[", '').replace("]", '').split("-")[0];
    const startDateTimeString = `${attributes.date} ${time}`;
    const endDateTimeString = attributes.end_date ? `${attributes.end_date} ${time}` : null;
    const userRole = this.state.role;
    const eventDate = moment(startDateTimeString, "MM-DD-YYYY hh:mm A");
    const endDate = attributes.end_date ? moment(endDateTimeString, "MM-DD-YYYY hh:mm A") : null;
    const isFuture = eventDate.isAfter(moment());
    const isDateInRange = endDate ? moment().isBetween(eventDate, endDate, undefined, '[]') : true;
    const isYouMatterSubmitted = attributes.submission_status === 'submitted';

    if (type === 'upcoming_events' && !this.state.showPopup) {
        await this.handleUpcomingEvent(id);
        this.switchPopup();
        return;
    }
    
    if (userRole !== 'student') return;
    
    if (!this.handleEventValidation(isDateInRange, isFuture, eventDate)) return;
        
    this.handleSpecificEventType(type, id, course_id, isYouMatterSubmitted);
  }

  async handleUpcomingEvent(id: string) {
    await this.getProgramDetails(id);
    const selectedEvent = this.state.events?.find((e: E) => e.id === id);
    if (selectedEvent) {
        this.setState({ selectedEvent });
    }
  }

  handleEventValidation(isDateInRange: boolean, isFuture: boolean, eventDate: moment.Moment): boolean {
      if (isFuture) {
          alert(`The event is scheduled on ${eventDate.format("DD-MM-YYYY [at] hh:mm A")}`);
          return false;
      }

      if (!isDateInRange) {
          alert("The event has expired");
          return false;
      }
      return true;
  }

  async handleSpecificEventType(type: string, id: string, courseId: number | undefined, isYouMatterSubmitted: boolean) {
      if (type === 'upcoming_you_matter_events') {
          if (isYouMatterSubmitted) {
              alert("The You Matter is already submitted");
              return;
          }
          localStorage.setItem("redirect_from", JSON.stringify({ id, type }));
          this.goTo('YouMatter');
      } else if (type === 'upcoming_assign_session_events') {
        localStorage.setItem("upcoming_session_id", JSON.stringify(id));
        await this.fetchCourseStatus(courseId);
      }
  }

  switchPopup = () => {
    this.setState({
      showPopup: !this.state.showPopup
    });
  };

  updateUserProfile = () => {
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateProfileApiCallId = apiRequest.messageId;
    const userId = this.state.userDetails.id;
    if (this.state.profileIcon) {
      const header = {
        Authorization: this.getAuthToken()
      };
      const formData = new FormData();
      if (this.state.tempPwd) {
        formData.append("password", this.state.tempPwd);
      }
      formData.append("profile_photo", this.state.profileIcon);
      this.makeApiCall(
        apiRequest.messageId,
        configJSON.dashboardPatchApiMethod,
        `${configJSON.Dashboard.updateUsersEndPoint}/${userId}`,
        header,
        formData
      );
    } else {
      if(this.state.role === "student"){
        const isValid = this.studentValidation();
        if (!isValid) {
          return;
        }
        this.updateProfileApi();
      }
      if(this.state.role === "trainer"){
        this.trainerSideValidation()
      }
      if (this.state.role === "school") {
         this.schoolSideValidation()
      }
      if(this.state.role === "parents"){
        this.parentSideValidation();
      }
    }
  };

  parentSideValidation = () => {
    const isValid = this.studentValidation();
    if (!isValid) {
      return;
    }
    if (this.state.userDetails.city === "") {
      this.setState({ cityError: true });
      return;
    }
    this.updateProfileApi();
  };
  schoolSideValidation = () => {
    const isValid = this.studentValidation();
    if (!isValid) {
      return;
    }
    const validateAddress = this.validationCheckForSchool();
    if (!validateAddress) {
      return;
    }
    this.updateProfileApi();
  }

  trainerSideValidation = () => {
    const isValid = this.studentValidation();
    if (!isValid) {
      return;
    }
    const validateAddress = this.validateForm();
        if(!validateAddress){
      return;
    }
    this.updateProfileApi();
  }

  updateProfileApi = () => {
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateProfileApiCallId = apiRequest.messageId;
    const userId = this.state.userDetails.id;
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getAuthToken()
    };
    const apiData: any = {
      avatar_key: this.state.avatarKey,
      address_line1: this.state.userDetails.address_line1,
      address_line2: this.state.userDetails.address_line2,
      state: this.state.userDetails.state,
      city: this.state.userDetails.city,
      pincode: this.state.userDetails.pincode,
    };
    if (this.state.userDetail != this.state.bio) {
      apiData.bio = this.state.bio;
    }
    if (this.state.tempPwd) {
      apiData.password = this.state.tempPwd;
    }
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardPatchApiMethod,
      `${configJSON.Dashboard.updateUsersEndPoint}/${userId}`,
      header,
      JSON.stringify(apiData)
    );
  }

  studentValidation = () => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/
    if (this.state.tempPwd === "") {
      alert(configJSON.Profile.paswwordRequiredError);
      return false;
    }
    if (this.state.confirmPwd === "") {
      alert(configJSON.Profile.confirmPasswordError);
      return false;
    }
    if (this.state.tempPwd !== this.state.confirmPwd) {
      alert(configJSON.Profile.confirmErrorForPass);
      return false;
    }
    if (!passwordRegex.test(this.state.tempPwd)) {
      alert(
        configJSON.Profile.letterLength
      );
      return false;
    }
    return true;
  }

  validateForm() {
    let updateErrorStatus = {
      address_line1: false,
      state: false,
      city: false,
      pincode: false,
      bios: false
    }
    this.validationCommon(updateErrorStatus);
    if(this.state.userDetails.bio === ""){
      updateErrorStatus.bios = true
    }
    this.setState({ validationErrors: { ...updateErrorStatus } });
    const valuesArray = Object.values(updateErrorStatus);
    const hasValidationError = valuesArray.filter((element: boolean) => {
      return element === true
    });
    if (hasValidationError.length < 1) {
      return true
    } else return false;
  } 

  validationCommon = (updateErrorStatus:ErrorStatus) => {
    if (this.state.userDetails.address_line1 === "") {
      updateErrorStatus.address_line1 = true
    }
    if (this.state.userDetails.state === "") {
      updateErrorStatus.state = true
    }
    if (this.state.userDetails.city === "") {
      updateErrorStatus.city = true
    }
    if(this.state.userDetails.pincode === "" || this.state.userDetails.pincode === null){
      updateErrorStatus.pincode = true
    }
  }

  validationCheckForSchool = () => {
    let updateErrorStatus = {
      address_line1: false,
      state: false,
      city: false,
      pincode: false,
    }
    this.validationCommon(updateErrorStatus);
    this.setState({ validationErrorsForSchool: { ...updateErrorStatus } });
    const valuesArray = Object.values(updateErrorStatus);
    const hasValidationError = valuesArray.filter((element: boolean) => {
      return element === true
    });
    if (hasValidationError.length < 1) {
      return true
    } else return false;
  }
 
  updateGroupProfile  = async (image: string) => {
    const userId = this.state.userDetails.id;
    const fullName = this.state.userDetails.first_name + ' ' + this.state.userDetails.last_name;
    const group = this.createCustomGroup(userId, fullName)
    group.setIcon(image)
    await CometChat.updateGroup(group);
 }
  getUserDetails() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.userDetailsApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getUserDetailsApiEndPoint,
      header
    );
  }

  getUserProgress() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.progressDetailApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getUserProgressEndPoint,
      header
    );
  }

  getMindMastery() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.mindMasteryApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getMindMateryEndPoint,
      header
    );
  }

  getUserReports() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.reportApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getReportEndPoint,
      header
    );
  }

  getUserSuperPowers() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.superPowerApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getSuperPowersEndPoint,
      header
    );
  }

  getNotes() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.notesApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getNotesEndPoint,
      header
    );
  }
  async getUpcomingEvents(currentDate?: Date) {
    let bodyParam = '';
    if(currentDate) {
      bodyParam = `?[date]=${moment(currentDate).format("DD-MM-YYYY")}`
    }
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.upcomingEventsApiCallId = apiRequest.messageId;
    const user = JSON.parse(`${localStorage.getItem(configJSON.Dashboard.UserDetails)}`)
    const role = user?.attributes?.role;
    let endpoint = configJSON.Dashboard.StudentUpcomingEventsEndPoint;
    
    switch(role){
      case configJSON.UserRole.Trainer: endpoint = configJSON.Dashboard.TrainerUpcomingEventsEndPoint ; break;
      case configJSON.UserRole.School: endpoint = configJSON.Dashboard.SchoolUpcomingEventsEndPoint; break;
      case configJSON.UserRole.Parents: endpoint = configJSON.Dashboard.ParentsUpcomingEventsEndPoint; break;
    }
    
    await this.getEvents();
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      (endpoint+ bodyParam),
      header
    );
  }

  getProgramDetails = async (id: string) => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.programDetailsApiCallId = apiRequest.messageId;
    await this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.Dashboard.getEventsEndPoint}/${id}`,
      header
    );
  }

  openLoginModal = () => {
    this.setState({ showProfileModal: !this.state.showProfileModal });
  }

  onLogOut = () => {
    CometChat.logout()
    .then(()=>{
      console.log(configJSON.Dashboard.UserLogout);
    })
    .catch((error)=>{
      console.error(error);
    })
    localStorage.removeItem("user_token");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("user_name");
    localStorage.removeItem("role");
    localStorage.removeItem("currentPinMessage");
    localStorage.removeItem("student_data");
    localStorage.removeItem("pinMessages");
    localStorage.removeItem("exp");
    localStorage.removeItem("currentName");
    this.goTo(configJSON.Dashboard.LoginForm);
  };

  openProfilePage = () => {
    this.goTo("Profile");
  };

  handlePage = (page: number) => {
    this.setState({ ongoingProgramIndex: 0, page: page });
  };

  handleRowClick = (isYouMatter:boolean,id: any, school: string, grade: string) => {
    const gradeData = grade.split('-');
    const gradeName = gradeData[0].trim();
    const sectionName = gradeData[1].trim();
    this.goTo(isYouMatter? configJSON.TrainerMPowerYouMatter.TrainerYouMatterCheckDashoard :configJSON.TrainerMPowerYouMatter.TrainerMpowerCheckDashoard, { mpowerYouMatterId: id, schoolName: school, gradeName: gradeName, sectionName: sectionName })
  }

  handleNavigationInSchoolParent = () => {
    if(this.getUserRole() === 'parents'){
      this.goTo(configJSON.Dashboard.ParentMaterial);
    }
  }

  getEvents = async () => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.eventsDataApiCallId = apiRequest.messageId;
    await this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      configJSON.Dashboard.getEventsEndPoint,
      header
    );
  };

  getMPower = async () => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiMPowerItemCallId = apiRequest.messageId;
    await this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      configJSON.Dashboard.TrainerStudentMpowerEndPoint,
      header
    );
  };

  getaYouMatter = async () => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiYouMatterItemCallId = apiRequest.messageId;
    await this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      configJSON.Dashboard.TrainerStudentYouMatterEndPoint,
      header
    );
  };
  getOnGoingPrograms = async () => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiOngoingProgramItemCallId = apiRequest.messageId;
    await this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      configJSON.Dashboard.TrainerOngoingProgramsEndPoint,
      header
    );
  };
  getUserDetail = async () => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUserDetailItemCallId = apiRequest.messageId;
    await this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      configJSON.Dashboard.TrainerDetailsEndPoint,
      header
    );
  };

  getSchoolDetails() {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSchoolDetailsApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      configJSON.getSchoolDetailsEndPoint,
      header
    );
  }

  getSchoolOngoingPrograms() {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSchoolOnGoingProgramsApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      configJSON.getOnGoingProgramsEndPoint,
      header
    );
  }

  getSchoolOnGoingProgramsList() {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSchoolOnGoingProgramsListApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      configJSON.getOnGoingProgramsListEndPoint,
      header
    );
  }

  getParentDetails() {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getParentDetailsApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      configJSON.getParentsDetails,
      header
    );
  }

  getChildrensOnGoingPrograms() {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getAuthToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getParentsChildrenProgramsApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      configJSON.getParentsChildrenDetails,
      header
    );
  }

  async makeApiCall(
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body?: any
  ) {
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;

    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallId
    );

    try {
      let response = await fetch(fullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body
      });
      if (response.status === 401) {
        localStorage.removeItem("userDetails");
        this.goTo("LoginForm");
      }
      let responseJson = await response.json();
      if(this.updateProfileApiCallId === uniqueApiCallId){
        if(responseJson.data){
          this.setState({successModal:true,tempPwd:"",confirmPwd:""})
        }
      }
      //setting Response
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
      // if token expires then redirect to login
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessage);
  }

  loginCometChatUser = async (userDetail:any) => {
    CometChat.init(config.appID, appSetting);
    CometChat.login(userDetail.id, config.authKey)
    .then(user => {
      console.log('User logged in:', user);
      // Proceed to API calls
    })
    .catch(error => {
      if (error.code === 'ERR_UID_NOT_FOUND') {
        this.checkCometChatUser(userDetail);
        // Proceed to user creation
      } else {
        console.error('Error while checking user:', error);
      }
      console.error('Error while logging in:', error);
    });
  }

  checkCometChatUser = async (userDetail:any) => {
      let user = new CometChat.User(userDetail.id);
      user.setName(userDetail.attributes.first_name+' '+userDetail.attributes.last_name);
      user.setRole(userDetail.attributes.role) //Role ID is in cometchat dashboard
      if(userDetail.attributes.image_url){
        user.setAvatar(userDetail.attributes.image_url);
      }
      CometChat.createUser(user, config.authKey).then(
      (user) => {
        console.log("User created successfully:", user);
        this.loginCometChatUser(userDetail);
      },
      (userError) => {
        console.log("User creation failed with error:", userError);
        alert("Something went wrong with our messaging system, Please contact your administrator")
      });
  }
  
  getParentGuideData() {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: localStorage.getItem("user_token")
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.ParentGuideApi = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      configJSON.ParentGuideApiEndPoint,
      header
    );
  }
  getData(responseJson: any, apiRequestCallId: any) {
    if (apiRequestCallId === this.ParentGuideApi) {
      this.setState({ ParentGuideData: responseJson });
    }
    if (apiRequestCallId === this.progressDetailApiCallId) {
      const progress = responseJson;
      this.setState({ progress: progress });
    }
  }

  createCustomGroup = (userId:number, fullName:string) => {
    return new CometChat.Group(
      configJSON.groupPrefix + userId.toString(),
      fullName,
      CometChat.GROUP_TYPE.PRIVATE
    );
  }

  handleModalOpen = () => {
    this.setState({ openCancel: !this.state.openCancel });
  };

  goBackCall = () => {
    this.goTo("DashboardPage");
  };

  handleDateChange(newDate: Date) {
    this.setState({ currentDate: newDate });
    this.getUpcomingEvents(newDate);
  }

  handleCloseModal = () => {
    this.setState({successModal : false })
  }

  async fetchCourseStatus(courseId: number | undefined) {
    const header = { "Content-Type": configJSON.programSchedulerContentType, Authorization: localStorage.getItem("user_token") };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.coursesStatusApiCallId = apiRequest.messageId;
    const endpoint = `${configJSON.MindMasteryTexts.courseDetails}?[mind_mastery_course_id]=${courseId}`;
    await this.makeApiCall(apiRequest.messageId, configJSON.dashboardGetApiMethod, endpoint, header);
  }

  handleCourseStatus(data: CourseStatus) {
    const courseId = data.attributes.mind_mastery_course_id;
    const status = data.attributes.status;
    const sessionId = localStorage.getItem("upcoming_session_id");
    localStorage.removeItem("upcoming_session_id");
    if ((status === 'started' || status === 'completed') && sessionId) {
      this.goTo('SessionsListPage', { courseId, sessionId: JSON.parse(sessionId) });
    } else {
      this.goTo('MindMasteryPage');
    }
  }
  // Customizable Area End

}
