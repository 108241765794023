// Customizable Area Start
import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import { Box, Typography, Switch, Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import {
  calendar,
  calendarActive,
  dashBoard,
  dashBoardActive,
  feedBack,
  feedBackActive,
  guide,
  guideActive,
  imageMonitor,
  imageMonitorActive,
  mPowerCheck,
  mPowerCheckActive,
  logo as mainHapchiLogo,
  mindMastery,
  mindMasteryActive,
  profile,
  seekHelp,
  seekHelpActive,
  topBarLeft,
  topBarRight,
  youMatter,
  youMatterActive,
} from "./assets";
import DashboardController from "./DashboardController";
import { Pagination } from "@material-ui/lab";
import ToggleSwitch from "../../../components/src/ToggleSwitch.web";
import { ProfileModal } from "./ProfileModal.web";
import _ from 'lodash'

interface UserType{
  student:string,
  parent:string,
  trainer:string
}
const configJSON = require("./config");

const drawerWidth = 268;

const useStyles = makeStyles<Theme, any>((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    drawer: {
      [theme.breakpoints.up("lg")]: {
        width: "268px",
        flexShrink: 0
      },
      "& .MuiDrawer-paper": {
        zIndex: "0",
        backgroundImage:
          "linear-gradient(34.86deg, #B3DBFF -36.53%, #4B90CD 17.76%, #0156A2 95.37%)",
        width: "268px"
      }
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: "100%"
      },
      "&.MuiAppBar-colorPrimary": {
        backgroundColor: "white"
      },
      "&.MuiAppBar-root": {
        height: "96px",
        display: "flex",
        justifyContent: "center",
        backgroundImage: `url(${topBarLeft}),url(${topBarRight})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top left ,top right"
      },
      "&.MuiPaper-root": {
        boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.0760489)"
      },
      "& .MuiIconButton-root": {
        color: "black",
        margin: "auto 0",
        fontFamily: "Poppins"
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        display: "none"
      },
      "& .MuiIconButton-root": {
        color: "black",
        fontFamily: "Poppins"
      }
    },
    arrowBack: { position: 'relative', left: '5px', color: "white" },
    arrowIcon: { backgroundColor: "#1067B5", padding: "0.6rem", marginTop: "10px", borderRadius: "5px" },
    toolbar: { height: (window.location.pathname !== "/trainer/queries" && window.location.pathname !== "/student/seek-help") ? "96px" : "" },
    drawerPaper: {
      width: drawerWidth,
      backgroundImage:
        "linear-gradient(34.86deg, #B3DBFF -36.53%, #4B90CD 17.76%, #0156A2 95.37%)"
    },
    content: {
      flexGrow: 1,
      padding: (props:{hideSidebar:string}) => `${props.hideSidebar ? 0 : "26px 32px 30px 32px"}`,
      display: (props:{hideSidebar:string}) => `${props.hideSidebar ? "inline-grid" : "block"}`,
      [theme.breakpoints.down("xs")]: {
        padding: (props:{hideSidebar:string}) => `${props.hideSidebar ? 0 : "26px 10px 30px 10px"}`
      }
    },
    navLinkText: {
      "& .MuiTypography-root": {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "27px",
        color: "#FFFFFF",
        fontFamily: "Poppins"
      }
    },
    navLinkIcon: {
      width: "32px",
      height: "32px"
    },
    navItem: {
      textDecoration: "none"
    },
    activeNavItem: {
      "& .MuiTypography-root": {
        color: "#EFD154",
        fontFamily: "Poppins"
      }
    },
    toolbarWrapper: {
      display: "flex",
      justifyContent: 'space-between'
    },
    profileIconWrapper: {
      justifyContent: "flex-end",
      display: "flex"
    },
    profileIcon: {
      width: "64px",
      height: "64px",
      borderRadius: "50%",
      border: "1px solid #0056A3",
      filter: "drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.246121))",
      [theme.breakpoints.down("xs")]: {
        width: "50px",
        height: "50px"
      }
    },
    mainLogoWrapper: {
      width: "auto",
      height: 80,
      maxWidth: "100%",
      display: "block",
      objectFit: "contain",
      [theme.breakpoints.down("xs")]: {
        height: 70
      }
    },
    mainLogoImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    courseTitle: {
      display: "flex",
      alignItems: "center",
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "33px",
      color: "#0056A3",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        lineHeight: "20px"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        lineHeight: "20px"
      }
    },
    youMatterMpowerTitle: {
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "33px",
      color: "#0056A3",
      marginLeft: '48px',
      [theme.breakpoints.down("sm")]: {
        marginLeft: '20px'
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        lineHeight: "20px"
      }
    },
    verticalLine: {
      width: "1px",
      height: "63px",
      margin: "0 31px 0 18px",
      background:
        "linear-gradient(137.61deg, #0056A3 0%, #09539E 14.18%, #000428 143.69%)",
      [theme.breakpoints.down("xs")]: {
        margin: "0 16px"
      }
    },
    viewSwitch: {
      '& .MuiSwitch-track': {
        backgroundColor: '#0056A3',
        borderRadius: '12px',
        opacity: 1
      },
      '&.MuiSwitch-root': {
        padding: '5px',
        height: '34px',
        width: '54px',
        marginLeft: 'auto'
      },
      '& .MuiSwitch-thumb': {
        height: '16px',
        width: '16px',
        backgroundColor: 'white'
      },
      '&.MuiSwitch-root.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#0056A3',
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#0056A3',
        opacity: 1
      }
    },
    gridSwitchItem: {
      '&.MuiGrid-item': {
        display: 'flex',
        alignItems: 'center'
      }
    },
    gridSwitchContainer: {
      '&.MuiGrid-container': {
        width: 'unset',
        marginLeft: 'auto',
        [theme.breakpoints.down("sm")]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }
      },

    },
    swichViewText: {
      color: '#0056A3',
      fontFamily: "Poppins",
      textAlign:'center',
      '@media(max-width:767px)': {
        fontSize: '12px'
      }
    },
    swichViewTextActive: {
      color: '#0056A3',
      fontWeight: 600,
      fontFamily: "Poppins",
      textAlign:'center',
      '@media(max-width:767px)': {
        fontSize: '12px'
      }
    },
    questions: {
      '& .MuiPagination-ul': {
        justifyContent: 'flexStart',
        marginLeft: '15px'
      },
      '& .MuiPaginationItem-rounded': {
        borderRadius: '8px'
      },
      '& .MuiPaginationItem-root': {
        backgroundColor: 'white',
        color: '0056A3',
        fontFamily: "Poppins",
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '22px',
        marginBottom: '15px',
        marginRight: '12px',
        height: '32px',
        minWidth: 'auto',
        width: '32px'
      },
      '& .MuiPaginationItem-outlinedPrimary.Mui-selected': {
        color: '0056A3',
        fontFamily: "Poppins",
        border: '1px solid rgba(63, 81, 181, 0.5)',
        backgroundColor: '#EFD154'
      },
      '& button[aria-label="Go to previous page"]:not(.Mui-disabled)': {
        color: 'white',
        border: '1px solid rgba(63, 81, 181, 0.5)',
        backgroundColor: '#0056A3'
      },
      '& button[aria-label="Go to next page"]:not(.Mui-disabled)': {
        color: 'white',
        border: '1px solid rgba(63, 81, 181, 0.5)',
        backgroundColor: '#0056A3'
      }
    },
    section: {
      color: 'white',
      fontFamily: "Poppins",
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      background: '#00000069',
      marginBottom: '18px',
      padding: '12px 24px',
      textTransform: 'capitalize'
    },
    attemptSummary: {
      color: 'white',
      fontFamily: "Poppins",
      fontSize: '18px',
      lineHeight: '27px',
      fontWeight: 700,
      padding: '14px 24px'
    },
    listViewSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginLeft: "35px",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        marginLeft: "0px",
      }
    },
    listViewSectionHeading: {
      color: "#0056A3",
      fontWeight: 700,
      fontSize: "20px",
      [theme.breakpoints.down("sm")]: {
        display: 'none',
      }
    },
    sliderText: {
      color: "#0056A3",
      fontSize: "16px",
      textAlign:'center'
    },
    sliderBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: 'flex',
        flexDirection: 'column',
      }
    },
    mobileOverlay: {
      position: 'relative',
    },
    sessionSidebar: {
      background:
        "linear-gradient(134.86deg, #B3DBFF -36.53%, #4B90CD 17.76%, #0156A2 95.37%)",
      minHeight: "100vh",
      height: "100%",
      width: "268px",
      "@media(max-width:1200px)": {
        display: (props:{mobileOpen:string}) => `${props.mobileOpen ? "block" : "none"}`,
        position: 'absolute',
        zIndex: 11,
      },
    },
    SummaryHeading: {
      fontSize: "18px",
      fontWeight: 700,
      color: "#fff",
    },
    SummarySpace: {
      padding: "30px 20px",
    },
    userDataName: {
      color: "#1067B5",
      marginTop: "5px"
    },
    onlineIcon: {
      backgroundColor: "#32A52D",
      border: "none",
      position: "relative",
      top: "2px",
      height: "20px",
      width: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%"
    },
    SummarySection: {
      background: "#0000002b",
      mixBlendMode: "normal",

      padding: "11px 24px",
    },
    section1Heading: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#fff",
    },
    counts: {
      padding: "11px 24px",
      display: "flex",
      flexWrap: 'wrap',
      gap: '8px',
    },
    numbers: {
      cursor: "pointer",
      width: "32px",
      height: "32px",
      borderRadius: "8px",
      color: "#0056A3",
      fontSize: "18px",
      fontWeight: 700,
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    activeColor: {
      backgroundColor: "#EFD154",
    },
    defaultColor: {
      backgroundColor: "#fff",
    },
    listItemIcon: {
      '&.MuiListItemIcon-root': {
        minWidth: 'auto',
        marginRight: '7px'
      }
    },
    listIconLessMargin: {
      '&.MuiListItemIcon-root': {
        minWidth: 'auto',
        marginRight: '4px'
      }
    },
    drawerTopSpace: {
      marginBottom: '96px'
    },
    listItem: {
      '&.MuiListItem-gutters': {
        paddingRight: '0'
      }
    }
  })
);
const getLinksByRole = (role:UserType) => {
  const defaultLinks = [
    {
      id: 1,
      text: configJSON.SidebarLayoutTexts.Dashboard,
      normalImage: dashBoard,
      activeImage: dashBoardActive,
      to: configJSON.SidebarLayoutTexts.DashboardPath
    },
    {
      id: 2,
      text: configJSON.SidebarLayoutTexts.ProgramSchedule,
      normalImage: calendar,
      activeImage: calendarActive,
      to: configJSON.SidebarLayoutTexts.StudentProgramSchedulePath
    },
    {
      id: 3,
      text: configJSON.SidebarLayoutTexts.MPowerCheck,
      normalImage: mPowerCheck,
      activeImage: mPowerCheckActive,
      to: configJSON.SidebarLayoutTexts.MPowerCheckPath
    },
    {
      id: 4,
      text: configJSON.SidebarLayoutTexts.MindMastery,
      normalImage: mindMastery,
      activeImage: mindMasteryActive,
      to: configJSON.SidebarLayoutTexts.MindMasteryPath
    },
    {
      id: 5,
      text: configJSON.SidebarLayoutTexts.YouMatter,
      normalImage: youMatter,
      activeImage: youMatterActive,
      to: configJSON.SidebarLayoutTexts.YouMatterPath
    },
    {
      id: 6,
      text: configJSON.SidebarLayoutTexts.SeekHelp,
      normalImage: seekHelp,
      activeImage: seekHelpActive,
      to: configJSON.SidebarLayoutTexts.SeekHelpPath
    },
    {
      id: 7,
      text: configJSON.SidebarLayoutTexts.ReviewAndFeedback,
      normalImage: feedBack,
      activeImage: feedBackActive,
      to: configJSON.SidebarLayoutTexts.ReviewAndFeedbackPath
    },
  ];

  if (role === configJSON.SidebarLayoutTexts.RoleTrainer) {
    const trainerLinks = defaultLinks.filter(link => ![3,4, 6].includes(link.id));

    const programVideos = {
      id: 4,
      text: configJSON.SidebarLayoutTexts.TrainingMaterial,
      normalImage: mindMastery,
      activeImage: mindMasteryActive,
      to: configJSON.SidebarLayoutTexts.TrainerMaterialPath
    };

    const report = {
      id: 6,
      text: configJSON.SidebarLayoutTexts.Report,
      normalImage: imageMonitor,
      activeImage: imageMonitorActive,
      to: configJSON.SidebarLayoutTexts.TrainerReportPath
    };

    trainerLinks.splice(2, 0, programVideos);
    trainerLinks.splice(4 , 0, report);

    return trainerLinks.map(element => {
      switch (element.id) {
        case 2: return { ...element, to: configJSON.SidebarLayoutTexts.TrainerProgramSchedulePath };
        case 3: return { ...element, text: configJSON.SidebarLayoutTexts.StudentsMPower, to: configJSON.SidebarLayoutTexts.TrainerMPowerPath };
        case 5: return { ...element, text: configJSON.SidebarLayoutTexts.StudentsYouMatter, to: configJSON.SidebarLayoutTexts.TrainerYouMatter };
        case 7: return { ...element, text: configJSON.SidebarLayoutTexts.AnswerQueries, to: configJSON.SidebarLayoutTexts.AnswerQueriesPath };
        default: return { ...element };
      }
    });
  }

  if (role === configJSON.SidebarLayoutTexts.RoleSchool) {
    const schoolLinks = defaultLinks.filter(link => ![3, 4, 5, 6].includes(link.id));

    const report = {
      id: 3,
      text: configJSON.SidebarLayoutTexts.Report,
      normalImage: imageMonitor,
      activeImage: imageMonitorActive,
      to: configJSON.SidebarLayoutTexts.SchoolReportPath
    };

    schoolLinks.splice(2, 0, report);

    return schoolLinks.map(element => {
      switch (element.id) {
        case 2: return { ...element, to: configJSON.SidebarLayoutTexts.SchoolProgramSchedulePath };
        case 7: return { ...element, text: configJSON.SidebarLayoutTexts.FeedbackReport, to: configJSON.SidebarLayoutTexts.SchoolFeedbackReportPath };
        default: return { ...element };
      }
    });
  }

  if (role === configJSON.SidebarLayoutTexts.RoleParents) {
    const parentLinks = defaultLinks.filter(link => ![3, 4, 5, 6, 7, 8].includes(link.id));

    const parentingGuide = {
      id: 3,
      text: configJSON.SidebarLayoutTexts.ParentingGuide,
      normalImage: guide,
      activeImage: guideActive,
      to: configJSON.SidebarLayoutTexts.ParentingGuidePath
    };

    const parentMaterial = {
      id: 4,
      text: configJSON.SidebarLayoutTexts.ParentMaterial,
      normalImage: mindMastery,
      activeImage: mindMasteryActive,
      to: configJSON.SidebarLayoutTexts.ParentMaterialPath
    };

    const childReport = {
      id: 5,
      text: configJSON.SidebarLayoutTexts.ChildReport,
      normalImage: imageMonitor,
      activeImage: imageMonitorActive,
      to: configJSON.SidebarLayoutTexts.ChildReportPath
    };

    const assistance = {
      id: 6,
      text: configJSON.SidebarLayoutTexts.Assistance,
      normalImage: seekHelp,
      activeImage: seekHelpActive,
      to: configJSON.SidebarLayoutTexts.AssistancePath
    };

    parentLinks.splice(2, 0, parentingGuide);
    parentLinks.splice(3, 0, parentMaterial);
    parentLinks.splice(4, 0, childReport);
    parentLinks.splice(5, 0, assistance);

    return parentLinks.map(element => {
      if (element.id === 2) {
        return { ...element, to: configJSON.SidebarLayoutTexts.ParentProgramSchedulePath };
      }
      return { ...element };
    });
  }

  return defaultLinks;
};

const renderSidebarLinks = (role:UserType) => {
  let links = getLinksByRole(role);
  return links
};

export const SidebarLayoutWrapper = (props: any) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles({ ...props, mobileOpen });
  const theme = useTheme();
  const user = JSON.parse(`${localStorage.getItem(configJSON.SidebarLayoutTexts.UserDetails)}`);
  const role = user?.attributes?.role || localStorage.getItem("role");
  const profileImage = user?.attributes?.image_url || profile;
  const profilePic = profileImage
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [activeLink, setActiveLink] = React.useState(null);

  const handleLinkClick = (linkId: any) => {
    setActiveLink(linkId);
    if (window.innerWidth <= 1200) {
      setMobileOpen(!mobileOpen);
    }
  };

  const path = window.location.pathname;

  let links = renderSidebarLinks(role);

  links.forEach(element => {
    if (path.includes(element.to)) {
      if (activeLink === null) {
        setActiveLink(element.id as any);
      }
    }
  });

  const handleClick = (id: number, active: number) => {
    props.handleQuestionClick(id, active);
  };
  const handleQuestionView = () => {
    props.checkQuestionView();
  };

  const drawer = (
    <>
      <List>
        {!props.isQuestionView && links.map(element => (
          <NavLink
            to={element.to}
            data-test-id="navLink"
            activeClassName={classes.activeNavItem}
            className={classes.navItem}
            onClick={() => handleLinkClick(element.id)}
            key={element.id}
          >
            <ListItem button >
              <ListItemIcon className={classes.listItemIcon}>
                <img className={classes.navLinkIcon} src={activeLink === element.id ? element.activeImage : element.normalImage} alt="" />
              </ListItemIcon>
              <ListItemText
                className={classes.navLinkText}
                primary={element.text}
              />
            </ListItem>
          </NavLink>
        ))}
        {props.isQuestionView && <>
          <Typography className={classes.attemptSummary}>Attempt Summary</Typography>
          <Typography className={classes.section}>Section - {props.youMatterSectionId}</Typography>
          <Pagination
            className={classes.questions}
            count={props.totalQuestions}
            hideNextButton
            hidePrevButton
            boundaryCount={props.totalQuestions}
            color='primary'
            size="large"
            page={props.questionNumber}
            variant="outlined"
            shape="rounded"
            data-test-id='pagination'
            onChange={(_event: Object, page: number) => { props.handleQuestionChange(page) }} />
        </>}
      </List>
    </>
  );

  return (
    <Box className={classes.root} data-test-id='root'>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbarWrapper}>
          {!props.hideSidebar && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              data-test-id="sidebarToggle"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box className={classes.mainLogoWrapper}>
            <img className={classes.mainLogoImage} src={mainHapchiLogo} alt="hapchi_logo" />
          </Box>
          {props.courseTitle && (
            <Typography className={classes.courseTitle}>
              <div className={classes.verticalLine} /> {props.courseTitle}
            </Typography>
          )}
          {props.youMatterTitle && <Typography className={classes.youMatterMpowerTitle}> {props.youMatterTitle} </Typography>}
          {
            (!props.isQuestionView && props.screen !== configJSON.mpowerQuestion) &&
            <Box className={classes.profileIconWrapper}>
              <IconButton onClick={props.openLoginModal}>
                <img
                  src={profilePic}
                  alt="profile icon"
                  className={classes.profileIcon}
                />
              </IconButton>
            </Box>
          }
          {props.screen === configJSON.mpowerQuestion && (
            <Box className={classes.listViewSection}>
              <Box>
                <Typography className={classes.listViewSectionHeading}>
                  MPower Check - [{configJSON.SidebarLayoutTexts.Section} {props.selectedSection}]
                </Typography>
              </Box>
              <Box className={classes.sliderBox}>
                <Typography className={classes.sliderText}>
                  {configJSON.SidebarLayoutTexts.QuestionView}
                </Typography>
                <Box>
                  <ToggleSwitch checkQuestionView={handleQuestionView} />
                </Box>
                <Typography className={classes.sliderText}>
                  {configJSON.SidebarLayoutTexts.ListView}
                </Typography>
              </Box>
            </Box>
          )}
          {props.isQuestionView &&
            <Grid container className={classes.gridSwitchContainer} >
              <Grid item className={classes.gridSwitchItem}>
                <Typography className={props.isListView ? classes.swichViewText : classes.swichViewTextActive}>Question View</Typography>
              </Grid>
              <Grid item className={classes.gridSwitchItem}>
                <Switch className={classes.viewSwitch} checked={props.listView} onChange={props.handleViewChange} />
              </Grid>
              <Grid item className={classes.gridSwitchItem}>
                <Typography className={props.isListView ? classes.swichViewTextActive : classes.swichViewText}>List View</Typography>
              </Grid>
            </Grid>
          }
        </Toolbar>
      </AppBar>
      {
        (!props.hideSidebar && props.screen !== configJSON.mpowerQuestion) && (
          <nav className={classes.drawer}>
            <Hidden mdUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                <div className={classes.drawerTopSpace} />
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        )}
      {props.screen === configJSON.mpowerQuestion && (
        <div className={classes.mobileOverlay}>
          <div className={classes.toolbar} />
          <Box className={classes.sessionSidebar}>
            <Box className={classes.SummarySpace}>
              <Typography className={classes.SummaryHeading}>
                {configJSON.SidebarLayoutTexts.AttemptSummary}
              </Typography>
            </Box>
            <Box className={classes.SummarySection}>
              <Typography className={classes.section1Heading}>
              {configJSON.SidebarLayoutTexts.Section} - {props.selectedSection}
              </Typography>
            </Box>
            <Box className={classes.counts}>
              {props.questionId.map((ids: number, index: number) => {
                return (
                  <Box onClick={() => handleClick(ids, index)} key={ids} className={`${classes.numbers} ${props.childState === index ? classes.activeColor : classes.defaultColor}`} >
                    {index + 1}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </div>
      )}
      <Box className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </Box>
    </Box>
  );
};

export default class SidebarLayout extends DashboardController {
  async componentDidMount(): Promise<void> {
    const userData = localStorage.getItem(configJSON.Dashboard.UserDetails);
    if (!userData) {
      this.getUserDetails();
    }
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    if (!_.isEqual(prevState.userDetails, this.state.userDetails)) {
      this.getUserDetails();
    }
  }
  render() {
    return (
      <>
        <SidebarLayoutWrapper
          data-test-id='wrapper'
          userDetails={this.state.userDetails}
          openLoginModal={this.openLoginModal}
          {...this.props}
        >
          <ProfileModal showProfileModal={this.state.showProfileModal} onClose={this.openLoginModal} onLogOut={this.onLogOut} openProfilePage={this.openProfilePage} userDetails={this.state.userDetails} />
          {this.props.children}
        </SidebarLayoutWrapper>
      </>
    );
  }
}
// Customizable Area End
